.LbMobileApp {
  font-family: sans-serif;
  text-align: center;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  max-width: 100vw;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
  max-width: 100vw;
}